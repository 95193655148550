@font-face {
    font-family: "BarlowSemiCondensed-Medium";
    src: url("/layout/fonts/BarlowSemiCondensed-Medium.ttf");
    src: url("/layout/fonts/BarlowSemiCondensed-Medium.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BarlowSemiCondensed-Regular";
    src: url("/layout/fonts/BarlowSemiCondensed-Regular.ttf");
    src: url("/layout/fonts/BarlowSemiCondensed-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BarlowSemiCondensed-Bold";
    src: url("/layout/fonts/BarlowSemiCondensed-Bold.ttf");
    src: url("/layout/fonts/BarlowSemiCondensed-Bold.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BarlowSemiCondensed-SemiBold";
    src: url("/layout/fonts/BarlowSemiCondensed-SemiBold.ttf");
    src: url("/layout/fonts/BarlowSemiCondensed-SemiBold.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}