/* ---------- Allgemeines ---------- */

body {
    margin: 0;
    padding: 0;
}

p {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.grid-container {
    padding-right: 8rem;
    padding-left: 8rem;
    max-width: 125.5rem;
    margin: 0 auto;
}

footer {
    position: relative;
    height: 86px;
    background-color: #ebebeb;
}

#burger {
    display: none;
}


/* ---------- NAV ---------- */

nav {
    width: 100%;
}

.top-bar {
    background: none;
    padding: 35px 50px 0px 50px;
}

.top-bar ul {
    background: none;
    padding-bottom: 12px;
}

.logo {
    width: 280px;
    padding: 10px 0px 0px 0px;
}

.menu li a {
    height: 31px;
    font-family: 'BarlowSemiCondensed-SemiBold';
    font-size: 26px;
    font-weight: 600;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    cursor: pointer;
}


/* ---------- Header ---------- */

.head-text {
    font-family: 'BarlowSemiCondensed-Medium';
    font-size: 80px;
    font-weight: 500;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.top-content {
    margin-top: 300px;
}

.mini-hr {
    width: 260px;
    height: 3px;
    border-radius: 0.5px;
    background-color: #ffffff;
    margin-left: 0px;
}

header {
    position: relative;
    height: 980.8px;
    background-image: linear-gradient(206deg, #015bf9, #17428d);
}

.rotate {
    margin: -135px 0px 0px 75px;
}

#my-element {
    position: absolute;
    animation-name: fade;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateY(100px);
}

#back {
    position: absolute;
    top: 51px;
    right: 50px;
}

#back a {
    font-family: 'BarlowSemiCondensed-Medium';
    font-size: 27px;
    color: white;
    font-weight: 500;
    font-style: normal;
    font-stretch: semi-condensed;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}


/* ---------- Unser Unternehmen ---------- */

#unser-unternhemen {
    padding: 115px 0px;
    position: relative;
}

.regular img {
    width: 325px !important;
}

.slick-dots {
    bottom: 0px !important;
}

.slick-dots li button:before {
    font-size: 28px !important;
}

.slick-dots li.slick-active button:before {
    color: #17428d !important;
    opacity: 1 !important;
}

.slick-dots li {
    margin: 0px 10px !important;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
    color: #17428d !important;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
    color: #17428d !important;
}

.slider {
    position: relative;
    height: 25rem;
    margin-top: 1.25rem;
    margin-bottom: 2.25rem;
    background-color: white;
}

.regular {
    width: 80% !important;
}

.regular ul {
    margin: 0;
}

.slick-slider {
    margin-top: -20px;
}

.slick-slide {
    text-align: center;
    margin-bottom: 50px;
}

.unternehmen-info-box h1 {
    font-family: 'BarlowSemiCondensed-Bold';
    font-size: 62px;
    font-weight: bold;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: 1.24px;
    text-align: left;
    color: #17428d;
    margin-bottom: 40px;
}

.unternehmen-info-box p {
    font-family: 'BarlowSemiCondensed-Medium';
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #17428d;
}


/* ---------- Unser Selbstverstaendnis ---------- */

#unser-selbstverstaendnis {
    padding: 110px 0px;
    background-color: #ebebeb;
}

.video-box video {
    width: 100%;
}

.selbstverstaendnis-info-box h1 {
    font-family: 'BarlowSemiCondensed-Bold';
    font-size: 62px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1.24px;
    text-align: left;
    color: #17428d;
    margin-bottom: 40px;
}

.selbstverstaendnis-info-box p {
    font-family: 'BarlowSemiCondensed-Medium';
    color: #17428d;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 2;
    letter-spacing: normal;
}


/* ---------- Unser Service ---------- */

#unser-service {
    margin-bottom: 0px;
    position: relative;
    padding: 60px 0px;
}

.service-box {
    width: 536px;
    height: 536px;
    background-color: #17428d;
    float: left;
    margin: 0px 15px 15px 0px;
}

.user-service-headtext h1 {
    font-family: 'BarlowSemiCondensed-Bold';
    font-size: 62px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.72px;
    text-align: left;
    color: #17428d;
    margin: 0px 0px 40px 0px;
}

.service-box-content {
    font-family: 'BarlowSemiCondensed-Regular';
    font-size: 24px;
    padding: 25px 45px 20px 45px;
    font-weight: normal;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.service-box video {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    display: block;
}

.animation-mini {
    height: 41%;
    padding: 15px;
}

.animation-mini img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

#zauberstabgif,
#puzzlegif,
#teamgif,
#abzeichengif {
    opacity: 0;
}

#zauberstabpic,
#puzzlepic,
#teampic,
#abzeichenpic {
    opacity: 1;
}

#puzzlegif,
#puzzlepic {
    width: 38%;
}

#abzeichengif,
#abzeichenpic {
    width: 38%;
}

#zauberstabgif,
#zauberstabpic {
    width: 33%;
}

#teamgif,
#teampic {
    width: 33%;
}

#abzeichengif {
    margin-top: -193px;
}

#puzzlegif {
    margin-top: -192px;
}

#zauberstabgif {
    margin-top: -167px;
}

#teamgif {
    margin-top: -167px;
}


/* ---------- Kontakt ---------- */

#kontakt {
    background-color: #17428d;
    padding: 20px 0px 75px 0px;
}

.kontakt-headtext {
    margin: 75px 0px 40px 0px;
}

.kontakt-icons {
    width: 25px;
    margin: 0px 20px 0px 0px;
}

.info-kontakt {
    margin-top: 8px;
}


/* #kontakt:before {
    clip-path: polygon(0px 0px, 100% 0px, 100% 87%, 0px 100%);
} */

.kontakt-headtext h1 {
    font-family: 'BarlowSemiCondensed-Bold';
    font-size: 63px;
    font-weight: bold;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.11;
    letter-spacing: 1.26px;
    text-align: left;
    color: #ffffff;
    margin-top: 100px;
}

.kontakt-content {
    font-family: 'BarlowSemiCondensed-Regular';
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.kontakt-content a {
    color: white;
}

.kontakt-info-box {
    width: 310px;
    height: 58px;
}

.standort img {
    float: left;
}

.standort p {
    line-height: 0.8;
    margin-bottom: 8.3px;
}


/*----------- Footer -----------*/

.footer-content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

.footer-content a {
    font-family: 'BarlowSemiCondensed-Regular';
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #17428d;
    margin: 0px 30px;
    cursor: pointer;
}

#scrolltop {
    position: absolute;
    bottom: 120px;
    right: 35px;
    cursor: pointer;
    outline: none;
}

#scrolltop img {
    width: 45px;
}


/*----------- Kanten ----------------*/

.kante-1 {
    width: 30%;
    height: 0;
    border-style: solid;
    border-width: 100px 0 0px 400px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    top: -98px;
    overflow: hidden;
}

.kante-2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 1520px 0px 0;
    border-color: transparent #ffffff transparent transparent;
    top: -99px;
    position: absolute;
    right: 0;
}

.kante-3 {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent;
    position: absolute;
    bottom: -139px;
    overflow: hidden;
}


/* .kante-4 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 118px 53px 0px 1920px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    bottom: 260px;
    overflow: hidden;
} */


/*----------- Datenschutz/Impressum -----------*/

.text-area {
    background: white;
    margin: -400px 50px 50px 50px;
    z-index: 167;
    position: relative;
    -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.5);
    padding: 50px 8rem;
}

.impressum-text h1,
.datenschutz-text h1 {
    font-family: 'BarlowSemiCondensed-Medium';
    font-size: 62px;
    font-weight: bold;
    color: #17428d;
}

.impressum-text h2,
.datenschutz-text h2 {
    font-family: 'BarlowSemiCondensed-Medium';
    font-size: 36px;
    font-weight: bold;
    color: #17428d;
}

.impressum-text h3,
.datenschutz-text h3 {
    font-family: 'BarlowSemiCondensed-Medium';
    font-size: 26px;
    font-weight: bold;
    color: #17428d;
}

.impressum-text p,
.datenschutz-text p,
li {
    font-family: 'BarlowSemiCondensed-Medium';
    font-size: 20px;
    font-weight: normal;
    color: #17428d;
}

@media only screen and (max-width:1440px) {
    header {
        height: 545px;
    }
    /* .footer-content {
        display: block;
        width: 60%;
    } */
    .grid-container {
        padding-right: 5rem;
        padding-left: 5rem;
        max-width: 125.5rem;
    }
    .head-text {
        font-size: 55px;
        line-height: 1.09;
    }
    .top-content {
        margin-top: 100px;
    }
    .mini-hr {
        width: 260px;
        height: 3px;
        border-radius: 0.5px;
        background-color: #ffffff;
        margin-left: 0px;
    }
    .rotate {
        display: none;
    }
    .slider-box img {
        width: 300px;
        margin: 250px 0px 0px 15px;
    }
    .unternehmen-info-box h1 {
        font-size: 36px;
        line-height: 1.39;
        letter-spacing: 0.72px;
    }
    .selbstverstaendnis-info-box h1 {
        font-size: 36px;
        line-height: 1.28;
        letter-spacing: 0.72px;
    }
    .selbstverstaendnis-info-box p {
        line-height: 1.8;
    }
    .service-box {
        width: 388px;
        height: 388px;
    }
    .service-box-content {
        font-size: 20px;
        line-height: 1.5;
        padding: 25px 28px 20px 28px;
    }
    .service-box video {
        width: 76px;
        height: 76px;
        margin: 0 auto;
        display: block;
    }
    #google-maps {
        height: 465px;
        margin-top: -100px;
    }
    .menu li a {
        font-size: 26px;
    }
}

@media only screen and (max-width:1024px) {
    .grid-container {
        padding-right: 4rem;
        padding-left: 4rem;
        max-width: 125.5rem;
    }
    #ulnav li {
        /* margin: 20px 10px 20px 10px; */
        margin: 110px 60px 20px 10px;
    }
    .text-area {
        margin: -350px 50px 50px 50px;
    }
    #my-element {
        transform: translateY(50px);
    }
    .head-text {
        font-size: 40px;
        line-height: 1.09;
    }
    .top-content {
        margin-top: 90px;
    }
    .video-box {
        margin-bottom: 50px;
    }
    .animation-mini img {
        padding: 20px;
    }
    #unser-unternhemen {
        padding: 110px 0px 50px 0px;
    }
    #unser-selbstverstaendnis {
        padding: 60px 0px;
    }
    .mini-hr {
        width: 115px;
        height: 3px;
        border-radius: 0.5px;
        background-color: #ffffff;
        margin-left: 0px;
    }
    .rotate {
        display: none;
    }
    .slider-box img {
        width: 270px;
        margin: 35px 0px 0px 0px;
    }
    .unternehmen-info-box p,
    .selbstverstaendnis-info-box p {
        font-size: 16px;
        line-height: 1.8;
    }
    .kontakt-headtext h1,
    .user-service-headtext h1,
    .unternehmen-info-box h1,
    .selbstverstaendnis-info-box h1 {
        font-size: 35px;
        line-height: 1.12;
        letter-spacing: 0.5px;
    }
    #puzzlegif,
    #puzzlepic {
        width: 43%;
        margin-top: 0px;
    }
    #abzeichengif,
    #abzeichenpic {
        width: 43%;
        margin-top: 0px;
    }
    #zauberstabgif,
    #zauberstabpic {
        width: 43%;
        margin-top: 0px;
    }
    #teamgif,
    #teampic {
        width: 43%;
        margin-top: 0px;
    }
    #puzzlepic,
    #abzeichenpic,
    #teampic,
    #zauberstabpic {
        display: none;
    }
    .service-box {
        width: 300px;
        height: 300px;
    }
    .service-box-content {
        padding: 5px 28px 20px 28px;
        font-size: 13px;
    }
    .user-service-headtext h1 {
        font-size: 30px;
    }
    /*--NAVI---*/
    #ulnav {
        display: none;
    }
    nav {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        background: #FFF;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        transition: all .375s;
    }
    nav.is-open {
        opacity: 1;
        z-index: 100;
        visibility: visible;
    }
    nav::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(206deg, #015bf9, #17428d);
        transform-origin: 0 0;
        transform: skew(-14deg) translateX(-120%);
        transition: all .275s .1s;
    }
    nav.is-open::before {
        transform: skew(-14deg) translateX(0);
    }
    nav ul {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        transform: translateX(-18%) skew(-16deg);
    }
    nav li {
        display: block;
        margin: .5rem 0;
        text-align: right;
        transform: skew(16deg);
    }
    nav ul,
    nav li {
        list-style: none;
        padding: 0;
    }
    nav a {
        display: block;
        padding: 12px 0;
        color: #5A3B5D;
        font-size: 1.4em;
        text-decoration: none;
        font-weight: bold;
    }
    .open-main-nav {
        position: absolute;
        top: 5px;
        padding-top: 20px;
        right: 15px;
        z-index: 1000;
        background: none;
        border: 0;
    }
    .open-main-nav:focus {
        outline: none;
    }
    .burger {
        position: relative;
        display: block;
        width: 18px;
        height: 2px;
        margin: 0 auto;
        background: #FFFFFF;
        transform: skew(5deg);
        transition: all .275s;
    }
    .burger:before {
        content: '';
        display: block;
        height: 100%;
        background: #FFFFFF;
        transition: all .275s;
        width: 25px;
        float: right;
    }
    .burger:before {
        transform: translateY(-7px) skew(-10deg);
    }
    /* Toggle State part */
    .is-open .burger {
        transform: skew(5deg) translateY(-8px) rotate(-46deg);
    }
    .is-open .burger:before {
        transform: translateY(0px) skew(-18deg) rotate(75deg);
    }
    .is-open .burger:after {
        transform: translateY(-12px) translateX(10px) skew(-20deg);
        opacity: 0;
    }
    #burger {
        display: block;
        padding: 60px 50px 0px 50px;
    }
    .service-box svg {
        width: 100px !important;
    }
    .regular img {
        width: 275px !important;
    }
    .slick-slider {
        margin-top: 58px;
    }
    .impressum-text h1,
    .datenschutz-text h1 {
        font-size: 42px;
    }
    .impressum-text h2,
    .datenschutz-text h2 {
        font-size: 32px;
    }
    .impressum-text h3,
    .datenschutz-text h3 {
        font-size: 22px;
    }
    .impressum-text p,
    .datenschutz-text p,
    li {
        font-size: 22px;
    }
}

@media only screen and (max-width:420px) {
    header {
        height: 305px;
    }
    .top-bar {
        padding: 15px 30px 0px 30px;
    }
    #burger {
        padding: 38px 30px 0px 30px;
    }
    .grid-container {
        padding-right: 2.3rem;
        padding-left: 2.3rem;
        max-width: 125.5rem;
        margin: 0 auto;
    }
    #unser-unternhemen {
        padding: 0px 0px 50px 0px;
    }
    .animation-mini {
        height: 41%;
        padding: 15px;
    }
    .regular {
        width: 100% !important;
        margin-bottom: 0px !important;
    }
    .top-content {
        margin-top: 50px;
    }
    #my-element {
        transform: translateY(30px);
    }
    .head-text {
        font-size: 25px;
        line-height: 1.09;
    }
    .kontakt-headtext h1 {
        font-size: 25px;
    }
    .mini-hr {
        width: 60px;
        height: 3px;
        border-radius: 0.5px;
        background-color: #ffffff;
        margin-left: 0px;
    }
    .regular img {
        width: 200px !important;
    }
    .slick-dots {
        bottom: 80px !important;
    }
    .rotate {
        display: none;
    }
    #ulnav li {
        margin: 60px 20px 20px 20px;
        /* margin: 65px 10px 20px 10px; */
    }
    .selbstverstaendnis-info-box p,
    .unternehmen-info-box p {
        font-size: 14px;
        line-height: 1.57;
    }
    .kontakt-content {
        font-size: 16px;
    }
    .kontakt-icons {
        width: 20px;
    }
    .service-box {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .service-box-content {
        font-size: 13px;
        line-height: 1.43;
    }
    .service-box video {
        width: 56px;
        height: 56px;
        margin: 0 auto;
        display: block;
    }
    .footer-content a {
        margin: 0px 10px;
        font-size: 14px;
    }
    .slider-box img {
        width: 300px;
        margin: 60px 0px 50px 0px;
    }
    .logo {
        width: 150px;
    }
    .kante-1,
    .kante-2 {
        top: -60px;
    }
    .kante-3 {
        bottom: -45px;
    }
    #kontakt {
        padding: 20px 0px 75px 0px;
    }
    .kontakt-headtext h1,
    .user-service-headtext h1,
    .unternehmen-info-box h1,
    .selbstverstaendnis-info-box h1 {
        font-size: 25px;
        line-height: 1.12;
        letter-spacing: 0.5px;
    }
    .animation-mini img {
        padding: 10px;
    }
    #puzzlegif {
        width: 50%;
        opacity: 0;
    }
    #abzeichengif {
        width: 50%;
        opacity: 0;
    }
    #zauberstabgif {
        width: 40%;
    }
    .text-area {
        -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
    }
    .text-area {
        margin: -200px 20px 50px 20px;
        padding: 22px 1rem;
    }
    #back {
        top: 25px;
    }
    .impressum-text h1,
    .datenschutz-text h1 {
        font-size: 25px;
    }
    .impressum-text h2,
    .datenschutz-text h2 {
        font-size: 18px;
    }
    .impressum-text h3,
    .datenschutz-text h3 {
        font-size: 16px;
    }
    .impressum-text p,
    .datenschutz-text p,
    li {
        font-size: 16px;
    }
    #back {
        position: absolute;
        right: 20px;
    }
}